<template>
  <div class="modal fade" ref="mdlDetallesArticulo" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content" style="border:none">
        <div class="modal-header text-white bg-info">
          <div class="modal-title text-center">
            <strong style="font-size:30px">Detalles del artículo</strong>
          </div>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="offset-md-4 col-md-3 col-8">
              <div class="divFotoArticulo">
                <img
                  :src="`${API}/articulos/${articulo.id}/fotos/principal?tipo_foto=256x256&_tk=${tk}`"
                  v-if="articulo.foto_principal" class="img-thumbnail"
                />
                <img :src="inventarioIcon" v-if="!articulo.foto_principal"/>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td style="width: 150px">
                        <strong>Nombre</strong>
                      </td>
                      <td style="width: 150px">
                        {{articulo.nombre}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Descripción</strong>
                      </td>
                      <td>
                        {{articulo.descripcion}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Familia de Artículos</strong>
                      </td>
                      <td>
                        {{articulo.familia.familia}}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>División</strong>
                      </td>
                      <td>
                        {{articulo.familia.division}}
                      </td>
                    </tr>
                    <tr v-show="articulo.marca != ''">
                      <td><strong>Marca</strong></td>
                      <td>{{articulo.marca}}</td>
                    </tr>
                    <tr v-show="articulo.modelo != ''">
                      <td><strong>Modelo</strong></td>
                      <td>{{articulo.modelo}}</td>
                    </tr>
                    <tr v-for="campo in articulo.campos" :key="campo.id">
                      <td>
                        <strong>{{campo.nombre}}</strong>
                      </td>
                      <td>
                        {{campo.valor}}
                        {{ 
                          campo.nombre != 'marca' &&
                          campo.nombre != 'modelo' &&
                          campo.unidad_metrica != null &&
                          ( campo.nombre != 'recipiente' || campo.unidad_metrica == 'Otro' ) ? 
                          '('+campo.unidad_metrica+')' : 
                          ''
                        }}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <strong>Cantidad</strong>
                      </td>
                      <td>
                        {{articulo.stock_total}}
                        {{articulo.unidad_medida.abreviatura}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 text-center">
              <button type="button" class="btn btn-outline-success" data-bs-dismiss="modal">
                <i class="mdi mdi-check-bold"></i>
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal';
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import inventarioIcon from '@/assets/img/inventario/inventario.png'
import {APIINV as API} from '@/API.js'
export default {
  name: 'MdlDetallesArticulo',
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      inventarioIcon: inventarioIcon,
      articulo: {
        nombre:'',
        familia: {},
        campos:[],
        descripcion: '',
        unidad_medida:{
          nombre: '',
          abreviatura: ''
        }
      },
      id_articulo: null
    }
  },
  methods: {
    mostrar(articulo) {
      var self = this
      self.id_articulo = articulo.id
      self.cargarArticulo()

      var modal = new Modal(this.$refs.mdlDetallesArticulo)
      modal.show()
    },

    cargarArticulo: function() {
      let self = this

      let idArticulo = self.id_articulo
      let params = {
          con_unidad_medida: true,
          con_familia: true,
          con_campos: true
      }

      ArticuloSrv.articuloJSON(idArticulo,params).then(response => {
        self.articulo = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar el articulo '+idArticulo
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
  },
}
</script>

<style scoped>
.divFotoArticulo {
  width:130%;
  height: 10%;
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}
.divFotoArticulo img {
  width: 100%;
}
.divFotoArticulo .btnEliminar {
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>