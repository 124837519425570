<template>
  <Layout tituloPagina="Inventario | Articulos">
    <div class="row">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-4 col-md-6 mb-3">
            <div class="input-group">
              <span class="input-group-text">
                Nombre de artículo
              </span>
              <input
                name="nombre_articulo"
                type="text"
                class="form-control"
                v-model="nombre"
                @keyup.enter="refrescarArticulos()"
                placeholder="Nombre del articulo"
              />
              <button class="btn btn-success" @click="refrescarArticulos()" title="Buscar">
                <i class="bx bx-filter-alt ms-1"></i>
              </button>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-3">
            <div class="input-group">
              <span class="input-group-text">
                Marca
              </span>
              <input
                name="marca_articulo"
                type="text"
                class="form-control"
                v-model="marca"
                @keyup.enter="refrescarArticulos()"
                placeholder="Marca del articulo"
              />
              <button class="btn btn-success" @click="refrescarArticulos()" title="Buscar">
                <i class="bx bx-filter-alt ms-1"></i>
              </button>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 mb-3 col-9">
            <div class="input-group">
              <span class="input-group-text">
                Modelo
              </span>
              <input
                name="modelo_articulo"
                type="text"
                class="form-control"
                v-model="modelo"
                @keyup.enter="refrescarArticulos()"
                placeholder="Modelo del articulo"
              />
              <button class="btn btn-success" @click="refrescarArticulos()" title="Buscar">
                <i class="bx bx-filter-alt ms-1"></i>
              </button>
            </div>
          </div>
          <div class="col-lg-2 col-md-6 col-3 text-right">
            <router-link
              :to="{ name: 'nuevoArticulo' }"
              v-show="tienePermiso('nuevoArticulo','vista')"
              class="btn btn-success"
            >
              <span class="d-none d-sm-block">
                <i class="mdi mdi-plus-thick"></i>
                Nuevo artículo
              </span>
              <span class="d-block d-sm-none">
                <i class="mdi mdi-plus-thick"></i>
              </span>
            </router-link>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6 mb-3">
            <div class="input-group" title="Familia de artículo">
              <span class="input-group-text">
                Familia de artículo
              </span>
              <select
                class="form-select"
                name="familia"
                v-model="id_familia"
                @change="refrescarArticulos()"
              >
                <option :value="null">Todos</option>
                <option
                  v-for="familia in familias"
                  :key="familia.id"
                  :value="familia.id"
                >
                  {{familia.division}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 col-md-12 text-center p-2 mb-3">
            <div class="form-check" style="display: inline-block;">
              <input
                class="form-check-input form-check-input-success"
                type="radio" name="tipoExistencia" id="todos"
                v-model="tipo_existencia" :value="1" @change="refrescarArticulos()"
              />
              <label class="form-check-label" :class="{'text-success': tipo_existencia == 1}" for="todos">
                <strong>Todos</strong>
              </label>
            </div>
            &nbsp;
            <div class="form-check" style="display: inline-block;">
              <input
                class="form-check-input form-check-input-info"
                type="radio" name="tipoExistencia" id="solo_existencias"
                v-model="tipo_existencia" :value="2" @change="refrescarArticulos()"
              />
              <label class="form-check-label" :class="{'text-info': tipo_existencia == 2}" for="solo_existencias">
                <strong>Solo existencias</strong>
              </label>
            </div>
            &nbsp;
            <div class="form-check" style="display: inline-block;">
              <input
                class="form-check-input form-check-input-warning"
                type="radio" name="tipoExistencia" id="con_existencia_baja"
                v-model="tipo_existencia" :value="3" @change="refrescarArticulos()"
              />
              <label class="form-check-label" :class="{'text-warning': tipo_existencia == 3}" for="con_existencia_baja">
                <strong>Con existencia baja</strong>
              </label>
            </div>
            &nbsp;
            <div class="form-check" style="display: inline-block;">
              <input
                class="form-check-input form-check-input-danger"
                type="radio" name="tipoExistencia" id="sin_existencias"
                v-model="tipo_existencia" :value="4" @change="refrescarArticulos()"
              />
              <label class="form-check-label" :class="{'text-danger': tipo_existencia == 4}" for="sin_existencias">
                <strong>Sin existencias</strong>
              </label>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="input-group" title="Orden de stock total">
              <span class="input-group-text">
                Orden de stock total
              </span>
              <select
                class="form-select"
                v-model="orden_stock_total"
                @change="refrescarArticulos()"
              >
                <option :value="1">Sin ordenar</option>
                <option :value="2">De menor a mayor</option>
                <option :value="3">De mayor a menor</option>
              </select>
            </div>
          </div>
        </div>
        <br>
        <div class="card">
          <div class="card-body" style="min-height: 475px;">
            <div class="row">
              <div class="col-md-12">
                <div class="table-responsive" style="min-height: 475px;">
                  <table id="tblListaArticulos" class="table table-hover">
                    <thead>
                      <tr>
                        <th style="width: 50px">Foto</th>
                        <th style="min-width: 100px">Id artículo</th>
                        <th style="min-width: 240px">Nombre del artículo</th>
                        <th>Cantidad</th>
                        <th style="min-width: 180px">Familia de artículos</th>
                        <th style="min-width: 200px">División</th>
                        <th style="min-width: 350px">Descripción</th>
                        <th style="min-width: 70px" class="text-center">Acciones</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(articulo,index) in articulos" :key="`art-${index+1}`">
                        <td>
                          <div style="min-height: 30px; display: inline-block">
                            <a
                              href="#"
                              onclick="return false;"
                              @click="mostarGaleriaFotosArticulo(articulo.id)"
                              v-if="articulo.foto_principal"
                            >
                              <img
                                :src="
                                  `${API}/articulos/${articulo.id}/fotos/principal?tipo_foto=64x64&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${tk}`
                                  //`${API}/articulos/${articulo.id}/fotos/principal?tipo_foto=64x64&_tk=${tk}`
                                "
                                style="width:30px; height:30px;"
                                class="iconarticulo"
                              />
                            </a>
                            <img :src="inventarioIcon" v-if="!articulo.foto_principal" style="width:30px; height:30px;"/>
                          </div>
                        </td>
                        <td class="fw-semibold">
                          <router-link
                            :to="{ name: 'edicionArticulo', params: { id: articulo.id } }"
                            v-show="tienePermiso('edicionArticulo','vista')"
                          >
                            {{articulo.id}}
                          </router-link>
                          <span v-show="!tienePermiso('edicionArticulo','vista')">
                            {{articulo.id}}
                          </span>
                        </td>
                        <td>
                          {{articulo.nombre}}
                        </td>
                        <td class="text-right fw-semibold">
                          <router-link 
                            :to="{ name: 'listadoMovimientosArticulos', query: { articulo: articulo.id } }"
                            :title="articulo.unidad_medida.nombre"
                          >
                            {{articulo.stock_total}}
                            {{articulo.unidad_medida.abreviatura}}
                          </router-link>
                        </td>
                        <td class="text-right">
                          {{articulo.familia.familia}}
                        </td>
                        <td class="fw-semibold">
                          {{articulo.familia.division}}
                        </td>
                        <td>
                          {{articulo.descripcion}}
                        </td>
                        <td class="text-center">
                          <div class="dropdown">
                            <button
                              class="btn btn-light btn-sm dropdown-toggle"
                              type="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i class="bx bx-dots-horizontal-rounded"></i>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end">
                              <li>
                                <button
                                  class="dropdown-item info"
                                  @click="detallesArticulo(articulo)"
                                >
                                  <i class="mdi mdi-eye font-size-15 text-info"></i>
                                  Detalle del artículo
                                </button>
                              </li>
                              <li>
                                <button
                                  class="dropdown-item primary"
                                  @click="duplicarArticulo(articulo)"
                                >
                                  <i class="mdi mdi-content-copy font-size-15 text-primary"></i>
                                  Duplicar
                                </button>
                              </li>
                              <li v-show="tienePermiso('edicionArticulo','vista')">
                                <router-link
                                  :to="{ name: 'edicionArticulo', params: { id: articulo.id } }"
                                  class="dropdown-item warning"
                                  v-show="tienePermiso('edicionArticulo','vista')"
                                >
                                  <i class="mdi mdi-content-save-edit-outline font-size-15 text-warning"></i>
                                  Editar
                                </router-link>
                              </li>
                              <li v-show="tienePermiso('inventario.articulos.eliminar','accion')">
                                <button
                                  class="dropdown-item danger"
                                  @click="preguntaEliminar(articulo)"
                                  v-show="tienePermiso('inventario.articulos.eliminar','accion')"
                                >
                                  <i class="mdi mdi-trash-can-outline font-size-15 text-danger"></i>
                                  Eliminar
                                </button>
                              </li>
                            </ul>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="text-center">
        <paginador
          :pag="page"
          :totpag="totpag"
          :epp="epp"
          @cargar-pagina="cargarPagina"
          @epp-actualizado="
            epp = $event,
            refrescarArticulos()
          "
        ></paginador>
      </div>
    </div>
    <mdl-detalles-articulo ref="mdlDetalles"></mdl-detalles-articulo>
    <cmp-galeria-fotos ref="galeria_articulo" :nombreGaleria="'galeria-articulo'" />
    <br>
  </Layout>
</template>

<script>
import Paginador from '@/components/Paginador.vue'
import MdlDetallesArticulo from './MdlDetallesArticulo.vue'
import FamiliaArticulosSrv from '@/services/inventario/FamiliaArticulosSrv.js'
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import CmpGaleriaFotos from "../CmpGaleriaFotos.vue"
import inventarioIcon from '@/assets/img/inventario/inventario.png'
import Layout from '@/views/layouts/main'
import { APIINV as API } from '@/API.js'
import { todoGetters } from "@/state/helpers"
import Swal from 'sweetalert2'
export default {
  name: 'ListadoArticulos',
  components: { 
    Layout,
    Paginador,
    MdlDetallesArticulo,
    CmpGaleriaFotos
  },
  data() {
    return {
      API: API,
      tk: localStorage.getItem('argusblack.token'),
      inventarioIcon: inventarioIcon,
      tipo_existencia: 1, // 1 = todos, 2 = solo_esistencia, 3 = sin_existencia, 4 = con_existencia_baja
      nombre: '',
      marca: '',
      modelo: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.listadoArticulos.por_pagina') || 10),
      tc: 0, // Total de clientes
      totpag: 0, // Total de páginas
      articulos: [],
      familias: [],
      id_familia: null,
      orden_stock_total: 1, // 1 = sin orden, 2 = asc, 3 = desc
    }
  },
  computed:{
    ...todoGetters
  },
  created() {
    var self = this

    if (this.$route.query.dato) this.dato = this.$route.query.dato
    if (this.$route.query.page) this.page = parseInt(this.$route.query.page)
    if (this.$route.query.epp) this.epp = parseInt(this.$route.query.epp)
    if (this.$route.query.nombre) this.nombre = this.$route.query.nombre
    if (this.$route.query.marca) this.marca = this.$route.query.marca
    if (this.$route.query.modelo) this.modelo = this.$route.query.modelo
    // if (this.$route.query.solo_existencias) this.solo_existencias = this.$route.query.solo_existencias == 'true'?true:false
    if (this.$route.query.tipo_existencia) this.tipo_existencia = parseInt(this.$route.query.tipo_existencia)
    if (this.$route.query.id_familia) this.id_familia = this.$route.query.id_familia
    if (this.$route.query.orden_stock_total) this.orden_stock_total = parseInt(this.$route.query.orden_stock_total)

    self.cargarFamilias()
    self.refrescarArticulos()
  },
  methods: {
    cargarFamilias: function() {
      var self = this

      let params = {
        sin_paginacion: true,
        solo_idfamdiv:true
      }

      FamiliaArticulosSrv.familiasJSON(params).then(response => {
        let page = response.data
        self.familias = page
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las familias de articulos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },
    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarArticulos({ page: n })
    },
    detallesArticulo: function(articulo) {
      this.$refs.mdlDetalles.mostrar(articulo)
    },
    duplicarArticulo:function(articulo) {
      var self = this

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Duplicar articulo?",
        html: "¿Está seguro de duplicar el artículo <br><b>"+articulo.nombre+"</b>?",
        icon: "question",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          ArticuloSrv.duplicar(articulo.id).then(response => {
            swal.fire("¡Duplicado!", "El articulo "+articulo.nombre+" se duplico correctamente.", "success");
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo duplicar el artículo '+ articulo.nombre
            }
            swal.fire("Error", mensaje, "error");
            console.log(error)
          }).finally(firn => {
            self.refrescarArticulos()
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },
    mostarGaleriaFotosArticulo: function(idArticulo) {
      var self = this
      self.$refs.galeria_articulo.mostrarGaleria(idArticulo)
    },
    preguntaEliminar: function(articulo) {
      var self = this
      if(!self.tienePermiso('inventario.articulos.eliminar','accion')){
        iu.msg.warning(
        `No tienes permiso para acceder a <strong style="color: #e65853">inventario.articulos.eliminar</strong>,
        revisa los permisos de rol con tu administrador de sistema`)
        
        return
      }

      const swal = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success me-1",
          cancelButton: "btn btn-danger"
        },
        buttonsStyling: false
      })

      swal.fire({
        title: "¿Eliminar articulo seleccionado?",
        html: `¿Estás seguro de eliminar el articulo <strong>${articulo.nombre}</strong>?`,
        icon: "warning",
        confirmButtonText: `<i class="mdi mdi-check-bold"></i> Si`,
        cancelButtonText: `<i class="mdi mdi-cancel"></i> No`,
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          ArticuloSrv.eliminar(articulo.id).then(response => {
            swal.fire("Eliminado!", "Se ha eliminado el artículo exitosamente", "success");
            self.refrescarArticulos()
          }).catch(error => {
            let mensaje
            try {
              mensaje = error.response.data.message
            } catch (e) {
              mensaje = 'No se pudo eliminar el articulo '+self.articulo.nombre
            }
            iu.msg.error(mensaje)
            console.log(error)
          })
        } else if ( result.dismiss === Swal.DismissReason.cancel ) {
          swal.fire("Cancelar", "Se canceló la operación correctamente", "error");
        }
      })
    },

    refrescarArticulos() {
      var self = this

      iu.spinner.mostrar('#tblListaArticulos')

      this.$router.replace({
        query: {
          page: self.page,
          epp: self.epp,
          nombre: self.nombre,
          marca: self.marca,
          modelo: self.modelo,
          tipo_existencia: self.tipo_existencia,
          id_familia: self.id_familia,
          orden_stock_total: self.orden_stock_total
        }
      }).catch(error => {})

      let params = {
        page: self.page,
        epp: self.epp,
        nombre: self.nombre,
        marca: self.marca,
        modelo: self.modelo,
        con_familia: true,
        id_familia: self.id_familia,
        con_unidad_medida: true
      }

      switch (self.tipo_existencia) {
        case 2:
          Object.assign(params, {
            solo_existencias: true
          })
        break;
        case 3:
          Object.assign(params, {
            con_existencia_baja: true
          })
        break;
        case 4:
          Object.assign(params, {
            sin_existencia: true
          })
        break;
      }

      switch (self.orden_stock_total) {
        case 2:
          Object.assign(params, {
            orden_stock_total: 'asc'
          })
        break;
        case 3:
          Object.assign(params, {
            orden_stock_total: 'desc'
          })
        break;
      }

      ArticuloSrv.articulosJSON(params).then(response => {
        localStorage.setItem('argusblack.listadoArticulos.por_pagina', self.epp)
        let page = response.data
        self.articulos = page.data

        
        self.tc = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarArticulos()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las familias de articulos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tblListaArticulos')
      })
    }
  },
  mounted: function() {
    iu.spinner.mostrar('#tblListaArticulos')
  }
}
</script>

<style scoped>
.form-check-input-success:checked {
  background-color: #63AD6F;
  color: #63AD6F;
  border-color: #63AD6F;
}
.form-check-input-warning:checked {
  background-color: #F9C256;
  border-color: #F9C256;
}
.form-check-input-danger:checked {
  background-color: #F56E6E;
  border-color: #F56E6E;
}
.form-check-input-info:checked {
  background-color: #57C9EB;
  border-color: #57C9EB;
}
.iconarticulo {
  width:30px;
  height:30px;
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>